@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Brand colors - Loyali App blue theme */
    --color-primary-50: 239 246 255;
    --color-primary-100: 219 234 254;
    --color-primary-200: 191 219 254;
    --color-primary-300: 147 197 253;
    --color-primary-400: 96 165 250;
    --color-primary-500: 59 130 246;
    --color-primary-600: 37 99 235;
    --color-primary-700: 29 78 216;
    --color-primary-800: 30 64 175;
    --color-primary-900: 30 58 138;
  }

  .dark {
    --bg-primary: 15 23 42;      /* slate-900 */
    --bg-secondary: 30 41 59;    /* slate-800 */
    --text-primary: 248 250 252; /* slate-50 */
    --text-secondary: 226 232 240; /* slate-200 */
  }

  .light {
    --bg-primary: 248 250 252;
    --bg-secondary: 241 245 249;
    --text-primary: 15 23 42;
    --text-secondary: 51 65 85;
  }
}

body {
  @apply bg-[rgb(var(--bg-primary))] text-[rgb(var(--text-primary))];
}